<template>
  <div class="audioVideo">
    <!--上传-->
    <div class="contentVideo">
      <div class="rightMenu">
        <!-- 右侧内容视频 列表- 分页 -->
        <div class="rightCon">
          <pagination2
            @complete="completeListDate"
            :hadPagination="false"
            :option="post"
            :url="'/DataBank/playbackList?sl_id=' + sl_id"
            ref="childBackVideochild"
          >
            <template v-slot:other="{ tableData: { courseInfo } }">
              <div class="header">
                <div class="headerChild" v-if="courseInfo">
                  <div class="headerChild1">
                    <p>课程 : {{ courseInfo.course_name }}</p>
                    <p>章节 : {{ courseInfo.name }}</p>
                  </div>
                  <div class="headerChild2">
                    <p>上课老师 : {{ courseInfo.teacher_name }}</p>
                    <p>
                      上课时间 :
                      {{
                        courseInfo.startTime
                          | formatTimeStamp('yyyy-MM-dd hh:mm')
                      }}
                    </p>
                  </div>
                  <div class="headerChild3">
                    <p>片段数量 : {{ courseInfo.allNum }}</p>
                    <p>
                      允许观看回放 :
                      {{ courseInfo.live_recall == 1 ? '已开启' : '已关闭' }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:default="{ tableData }">
              <playback-footage
                :dialogstatus.sync="playbackFootagestatus"
                ref="playbackFootage"
                @complete="complete"
                @uploadConfirmClose="close"
                @close="close"
              >
                <el-button
                  type="primary"
                  @click="playbackFootagestatus = !playbackFootagestatus"
                  style="margin: 20px"
                >
                  添加回放片段
                </el-button>
              </playback-footage>

              <el-table
                :data="dataList"
                @sort-change="sortChange"
                @selection-change="handleSelectionChange"
                @select-all="selectAll"
                max-height:200
                ref="tablelist"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="file_name" label="视频" min-width="245">
                  <template slot-scope="scope">
                    <div class="TitleVideo">
                      <div class="imgVideo">
                        <img
                          :src="
                            scope.row.video_img
                              ? scope.row.video_img
                              : require('../../assets/img/1.3.9/icon_spbsR.png')
                          "
                          alt=""
                        />
                        <div
                          class="encryption"
                          v-if="scope.row.is_video_encryption == 1"
                        >
                          <span v-if="scope.row.zm_spec == 1">720p</span>
                          <span v-else-if="scope.row.zm_spec == 2">1080p</span>
                          <span v-else>-</span>
                        </div>
                        <div class="videoTime">
                          <p>{{ scope.row.duration | formatTimeLength }}</p>
                        </div>
                      </div>
                      <div class="textVideo">
                        <p :title="scope.row.file_name">
                          {{ scope.row.file_name }}
                        </p>
                        <h5>
                          <img
                            v-if="scope.row.is_video_encryption == 2"
                            src="../../assets/img/icon_cw@2x.png"
                            alt=""
                          />
                          <img
                            v-if="
                              scope.row.is_video_encryption == 1 ||
                                scope.row.is_video_encryption == 3
                            "
                            src="../../assets/img/icon_dg@2x.png"
                            alt=""
                          />
                          <span
                            style="color: #888888"
                            v-if="scope.row.is_video_encryption == 1"
                          >
                            已加密
                          </span>
                          <span
                            style="color: #888888"
                            v-if="scope.row.is_video_encryption == 2"
                          >
                            未加密
                          </span>
                          <span
                            style="color: #888888"
                            v-if="scope.row.is_video_encryption == 3"
                          >
                            加密中
                          </span>
                          <!-- 1未转码  2已转码  3转码中 -->
                          <img
                            v-if="scope.row.transcode_status == 2"
                            src="../../assets/img/1.3.9.4/icon_dg.png"
                            alt=""
                            class="ml10"
                          />
                          <img
                            class="ml10"
                            v-else-if="scope.row.transcode_status == 1"
                            src="../../assets/img/1.3.9.4/icon_cw.png"
                            alt=""
                          />
                          <img
                            class="ml10"
                            v-else
                            style="height: 10px; width: 10px; margin-top: 2px"
                            src="../../assets/img/1.3.9.4/icon_zmz.png"
                            alt=""
                          />
                          <span v-if="scope.row.transcode_status == 2">
                            视频已转码
                          </span>
                          <span v-if="scope.row.transcode_status == 1">
                            视频未转码
                          </span>
                          <span v-if="scope.row.transcode_status == 3">
                            视频转码中
                          </span>
                        </h5>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="类型">
                  <template slot-scope="{ row }">
                    <span v-if="row.upload_type == 1">系统生成</span>
                    <span v-else>手动添加</span>
                  </template>
                </el-table-column>

                <el-table-column prop="file_size" label="大小" min-width="80px">
                  <template slot-scope="scope">
                    <div class="VideoSizeBox">
                      <p
                        :title="
                          scope.row.is_video_encryption == 1
                            ? '原视频大小'
                            : false
                        "
                      >
                        {{
                          scope.row.file_size
                            ? scope.row.file_size
                            : 0 | getSize
                        }}
                        <a v-if="scope.row.is_video_encryption == 1">
                          (原视频)
                        </a>
                      </p>
                      <p
                        v-if="scope.row.is_video_encryption == 1"
                        :title="
                          scope.row.is_video_encryption == 1
                            ? '加密视频大小'
                            : false
                        "
                      >
                        {{
                          scope.row.zm_video_size
                            ? scope.row.zm_video_size
                            : 0 | getSize
                        }}
                        <a v-if="scope.row.is_video_encryption == 1">
                          (加密视频)
                        </a>
                      </p>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="add_time" label="生成时间">
                  <template slot-scope="scope">
                    {{ scope.row.ctime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </template>
                </el-table-column>

                <el-table-column prop="playback_end_time" label="回放有效期">
                  <template slot-scope="{ row }">
                    <span v-if="row.type == 1">
                      永久
                    </span>
                    <span v-else>
                      {{
                        row.playback_end_time | formatTimeStamp('yyyy-MM-dd')
                      }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  label="操作"
                  fixed="right"
                  min-width="210px"
                  align="right"
                >
                  <template slot-scope="scope">
                    <div class="btns">
                      <el-button
                        type="text"
                        @click="settingTimeclick(scope.row)"
                      >
                        设置
                      </el-button>
                      <span class="space"></span>
                      <el-button
                        :disabled="scope.row.is_video_encryption == 3"
                        type="text"
                        @click="
                          cilckPreViewVideo(
                            scope.row.is_video_encryption,
                            scope.row.zmvidoe_url || scope.row.video_url
                          )
                        "
                      >
                        播放
                      </el-button>
                      <span class="space"></span>
                      <el-button
                        :disabled="scope.$index == 0"
                        type="text"
                        @click="upDownMove(scope.row.recording_id, 1)"
                      >
                        上移
                      </el-button>
                      <span class="space"></span>
                      <el-button
                        :disabled="scope.$index == tableData.list.length - 1"
                        type="text"
                        @click="upDownMove(scope.row.recording_id, 2)"
                      >
                        下移
                      </el-button>
                      <template>
                        <span class="space"></span>
                        <el-dropdown>
                          <span class="text">更多</span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                              <p
                                @click="
                                  renameVideo(
                                    scope.row.recording_id,
                                    scope.row.file_name
                                  )
                                "
                              >
                                重命名
                              </p>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <p @click="delVideo(scope.row.recording_id)">
                                删除
                              </p>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </template>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <template v-slot:footer>
              <div class="footer">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checked"
                  @change="boxChange($event)"
                ></el-checkbox>
                <span class="ml10">已选择{{ selected.length || 0 }} 条</span>
                <el-button
                  type=""
                  size="medium"
                  :disabled="selected.length == 0"
                  @click="dels"
                >
                  批量删除
                </el-button>
              </div>
            </template>
          </pagination2>
          <!--分页-->
        </div>
      </div>
    </div>

    <previewVideo
      v-if="previewSrc"
      :src="previewSrc"
      @close="preViewVideo"
    ></previewVideo>

    <!-- 重命名 -->
    <el-dialog
      title="文件重命名"
      :visible.sync="renameVideodialogVisible"
      width="522px"
      :close-on-click-modal="false"
    >
      <el-input
        type="text"
        placeholder="输入新的文件名"
        v-model="renameVideotext"
        maxlength="50"
        show-word-limit
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click.stop="renameVideoCancel">取 消</el-button>
        <el-button type="primary" @click.stop="renameVideoFix">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 设置时间 -->
    <el-dialog
      title="设置"
      :visible.sync="settingTime"
      width="452px"
      v-if="settingTime"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="回放有效期">
          <el-radio-group v-model="form.type">
            <div style="margin-top:13px">
              <el-radio :label="1">永久</el-radio>
            </div>
            <div class="mt30 ">
              <div class="flex-center">
                <el-radio :label="2">限时</el-radio>
                <el-date-picker
                  v-if="form.type == 2"
                  v-model="form.playback_end_time"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </div>
            </div>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="tips">
        到期后，学员无法观看回放内容，但仍可以进入直播间查看聊天内容
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingTime = false">取 消</el-button>
        <el-button type="primary" @click="timeSubmit">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import playbackFootage from '@cm/playbackFootage'
import dataBase from '@/mixin/dataBase'
import previewVideo from '@cm/coursePop/previewVideo'

export default {
  name: 'audioVideo',

  mixins: [dataBase],

  components: {
    previewVideo,
    playbackFootage,
  },

  data() {
    return {
      form: {
        recording_id: '',
        type: 1,
        playback_end_time: '',
      },
      settingTime: false,
      isIndeterminate: false,
      checked: false,
      dataList: [],
      playbackFootagestatus: false,
      headerInfo: {},
      sl_id: 0,
      videoIdArr: [],
      ncryptionVideoId: '',
      isencryptionVideoType: 0,
      renameVideoId: '',
      renameVideotext: '',
      isBg: true,
      selectedNum: 0,
      selected: '',
      renameVideodialogVisible: false,
      tableData: [],
      post: {
        sl_id: '',
      },
      postBack: {
        sl_id: 774,
      },
      total: 0,
    }
  },

  watch: {
    selectedNum(val) {
      if (val > 0) {
        if (val == this.dataList.length) {
          this.checked = true
          this.isIndeterminate = false
        } else if (val < this.dataList.length) {
          this.isIndeterminate = true
          this.checked = false
        }
      } else {
        this.isIndeterminate = false
        this.checked = false
      }
    },
  },

  created() {
    //type=1用于获取 视频 菜单
    this.sl_id = this.$route.params.sl_id
    this.post.sl_id = this.sl_id
  },

  methods: {
    async timeSubmit() {
      await this.$http({
        url: '/DataBank/setPlayBackEndTime',
        data: _.assign({}, this.form, {
          playback_end_time: this.form.playback_end_time / 1000,
        }),
        successMsg: true,
      })
      this.settingTime = false
      this.$refs.childBackVideochild.reset()
    },

    settingTimeclick(val) {
      this.form = _.assign({}, val, {
        playback_end_time: val.playback_end_time
          ? val.playback_end_time * 1000
          : '',
      })
      this.settingTime = true
    },

    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },

    // 列表下边的多选
    boxChange(val) {
      // this.tablelistLength = val2.list.length
      if (val) {
        this.$refs.tablelist.toggleAllSelection()
      } else {
        this.$refs.tablelist.clearSelection()
      }
    },

    //多选后触发
    handleSelectionChange(val) {
      this.videoIdArr = []
      this.selected = val
      this.selectedNum = val.length
      //筛选出多选后视频id
      val.forEach(item => {
        this.videoIdArr.push(item.recording_id)
      })
    },

    // 上移下移
    upDownMove(recording_id, sort) {
      this.$http({
        url: '/DataBank/playbackSort',
        data: {
          recording_id: recording_id,
          sort: sort,
        },
        callback: () => {
          this.$refs.childBackVideochild.reset()
          this.$root.prompt({
            type: 'success',
            msg: '操作成功',
          })
        },
      })
    },

    // 回调的数据
    completeListDate(val) {
      this.dataList = val.data.list
    },

    close() {
      this.playbackFootagestatus = false
      this.$refs.childBackVideochild.reset()
    },

    async complete(val) {
      await this.$http({
        url: '/DataBank/playbackCopy',
        data: {
          sl_id: this.$route.params.sl_id,
          video_ids: val
            .filter(item => !!item.video_id)
            .map(item => item.video_id),
          recording_ids: val
            .filter(item => !!item.recording_id)
            .map(item => item.recording_id),
        },
      })
      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
      this.$refs.childBackVideochild.reset()
    },

    download(src) {
      this.$store.dispatch('open', src)
    },

    trimStr(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },

    //批量删除
    dels() {
      this.delVideo(this.videoIdArr)
    },

    // type: 类型 1 音视频 2 回放管理 3 文档
    // sort:  排序   desc 最新 asc 最旧
    // search:  搜索关键字
    getData(data) {
      this.$http({
        url: '/DataBank/playbackList',
        data,
        callback: ({ data, count }) => {
          this.total = Number(count)
          this.tableData = data
        },
        error: () => {
          this.total = 0
          this.tableData = []
        },
      })
    },

    // 排序
    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      switch (value.prop) {
        case null:
          break
        case 'file_size':
          order = 1
          break
        case 'ctime':
          order = 2
          break
        case 'add_time':
          order = 3
          break
      }
      this.postBack.order = order
      this.postBack.sort = sort
    },

    // 删除视频

    //删除视频

    // 获取机构存储空间

    //删除视频单个
    delVideo(id) {
      this.$confirm('是否删除已选文件，删除后可在回收站中查看！', '删除文件', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$http({
            url: '/DataBank/deleteRecording',
            data: {
              ids: id,
              type: 2,
            },
            callback: () => {
              this.$root.prompt({
                msg: '删除成功',
                type: 'success',
              })
              this.$refs.childBackVideochild.reset()
            },
            error: () => {
              this.$root.prompt('删除失败')
            },
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    //移动视频

    //移出该分组

    //重命名视频
    renameVideo(id, name) {
      this.renameVideodialogVisible = true
      this.renameVideoId = id
      this.renameVideotext = name
    },
    renameVideoCancel() {
      this.renameVideodialogVisible = false
      this.renameVideotext = ''
    },
    renameVideoFix() {
      this.renameVideotext = this.trimStr(this.renameVideotext)
      var regTextChar = new RegExp('[\\\\*:<>/|?""]')
      if (
        regTextChar.test(this.renameVideotext) ||
        this.renameVideotext == ''
      ) {
        this.$root.prompt('文件名不能为空且不能包含特殊符号：\\ /:*?"<>|')
      } else {
        this.$http({
          url: '/DataBank/setFileName',
          data: {
            type: 2,
            id: this.renameVideoId,
            name: this.renameVideotext,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.$refs.childBackVideochild.reset()
              this.renameVideodialogVisible = false
              this.renameVideotext = ''
            }
          },
        })
      }
    },

    //加密
    encryptionVideo(id, vid) {
      //有vid为腾讯上传视频,可以加密,没有是七牛
      if (vid) {
        this.encryptionVideodialogVisible = true
        this.ncryptionVideoId = id
      } else {
        this.$root.prompt(
          '该文件为加密功能上线前的数据，暂不支持加密，如需加密请重新上传！'
        )
      }
    },
    encryptionVideoCancel() {
      this.encryptionVideodialogVisible = false
    },
    encryptionVideoFix() {
      this.$http({
        url: '/DataBank/encryption',
        data: {
          type: 2,
          id: this.ncryptionVideoId,
          zm_spec: this.isencryptionVideoType,
        },
        callback: ({ code }) => {
          if (code == 200) {
            this.$refs.childBackVideochild.reset()
            this.encryptionVideodialogVisible = false
            this.ncryptionVideoId = ''
            this.isencryptionVideoType = 0
          }
        },
      })
    },
    //判断点击播放是否是加密视频,是则弹出错误
    cilckPreViewVideo(isJm, url) {
      if (isJm == 1) {
        this.$root.prompt('视频已加密,不支持直接在线播放')
      } else {
        this.preViewVideo(url)
      }
    },
  },
  filters: {
    getSize(val) {
      // if (val / 1024 >= 1) {
      //   return (val / 1024).toFixed(2) + 'G'
      // } else {
      return val + 'MB'
    },
    getStr(str) {
      function strlen(str) {
        var len = 0
        for (var i = 0; i < str.length; i++) {
          var c = str.charCodeAt(i)
          //单字节加1
          if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            len++
          } else {
            len += 2
          }
        }
        return len
      }
      if (strlen(str) >= 18) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tips {
  font-size: 12px;
  font-weight: 400;
  color: #6a6a6a;
  line-height: 17px;

  margin-left: 100px;
  transform: translateY(-12px);
}
::v-deep .el-input__icon {
  line-height: 2.8;
}
::v-deep .el-table {
  width: calc(100% - 40px) !important;
  margin-left: 20px;
}
.ppp {
  width: 90px;
  text-align: left;
}

.zJName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assistant-ps {
  position: fixed;
  top: 18px;
  left: 253px;
  z-index: 9;
}

.el-dropdown-menu__item {
  width: 80px;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.isbg {
  background: #f0f8ff;
}

.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-left: 62px;

  .el-button {
    width: 136px;
    margin-top: 20px;
    margin-left: 20px;
  }
}

.VideoSizeBox {
  height: 100%;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin: 10px 0;

    a {
      color: #999;
    }
  }
}

.TitleVideo {
  display: flex;

  .imgVideo {
    width: 150px;
    height: 80px;
    position: relative;
    overflow: hidden;

    img {
      width: 140px;
      height: 80px;
    }

    .encryption {
      position: absolute;
      top: 0;
      width: 38px;
      height: 22px;
      border-radius: 0px 0px 11px 0px;
      overflow: hidden;
      background: #fa6400;

      span {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 18px;
        margin-left: 4px;
      }
    }

    .videoTime {
      width: 140px;
      height: 20px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      text-align: center;

      p {
        height: 12px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
      }
    }
  }

  .textVideo {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;

    p {
      min-width: 68px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    // h4 {
    //   font-size: 12px;
    //   font-family: MicrosoftYaHei;
    //   color: #999999;
    //   line-height: 14px;
    //   margin-top: 15px;
    //   font-weight: normal;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }

    h5 {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 14px;
      margin-top: 13px;
      font-weight: normal;
      margin-top: 40px;
      display: flex;

      img {
        width: 8px;
        height: 7px;
        margin-top: 3px;
      }

      span {
        margin-left: 5px;
      }
    }
  }
}

.contentVideo {
  position: relative;
  display: flex;
  height: 100%;

  .rightMenu {
    flex: 1;
    position: relative;
    overflow-x: hidden;
  }
}

.audioVideo {
  background: #fff;
}

::v-deep .audioVideo .el-table {
  padding: 0 20px;
}

.header {
  display: flex;
  // margin-bottom: 40px;
  min-width: 850px;
  padding: 20px;
  height: 47px;
  border-bottom: 20px solid rgb(245, 245, 245);

  .headerChild {
    height: 47px;
    width: 100%;
    display: flex;

    div {
      flex: 1;

      p:nth-child(2) {
        margin-top: 20px;
      }

      p {
        height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 12px;
      }
    }
  }
}

.btns {
  @extend %btns;
  position: relative;
  justify-content: flex-end;
}

.moreRightMenuAll {
  width: 136px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  position: absolute;
  left: -45px;
  z-index: 9;
  display: none;

  div:hover {
    background-color: #f0f8ff;
  }

  div {
    height: 40px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    p {
      margin-left: 20px;
      line-height: 40px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #4a4a4a;
    }
  }
}
</style>
